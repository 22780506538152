<template>
    <component :is="widgetRenderer" v-bind="widgetProps"></component>
</template>

<script setup lang="ts">
import { ref, watch, defineAsyncComponent} from 'vue';
import { getOrCreateDataObject } from "o365-dataobject";
import { useDataObjectEventListener } from 'o365-vue-utils';
import { app } from 'o365-modules';

export interface IProps {
    //A row with dataobject, chartconfig, 
    chartConfig: object,
    //URL to filter out dataobject, if not provided will use default parameters.
    url: string
};

function useAsyncComponent(pImport) {
    return defineAsyncComponent(() => import(pImport));
}

const props = defineProps<IProps>();

const widgetRenderer = ref();
const widgetProps = ref();
const chartDataObject = ref(null);

const emits = defineEmits(['loaded'])

watch(() => props.chartConfig, () => {
    if ( props.chartConfig ) {
        initWidget();
    } else {
        clearConfig();
    }
}, { immediate: true });

async function initWidget() {
    const config = JSON.parse(props.chartConfig.ChartConfigJson);
    if (config.type == 'highchart') {

        if (config.options == null || config.options.dataObject == null) { clearConfig(); return; }

        widgetRenderer.value = useAsyncComponent('o365.libraries.vue.components.HighChart.vue');
        widgetProps.value = {
            dataObject: createDynamicDataObject(config.options.appId ?? app.id),
            options: config.options,
            loadDataObject: true
        }
    } else {
        clearConfig();
    }
}

function clearConfig() {
    widgetRenderer.value = undefined;
    widgetProps.value = undefined;
}

function createDynamicDataObject(appID: string){
    chartDataObject.value = getOrCreateDataObject(JSON.parse(props.chartConfig.DataObjectConfigJson), appID + crypto.randomUUID());
    const appConfig = JSON.parse(props.chartConfig.AppConfig);
    
    let sqlStatementParameters = {}
    const urlParamsObj = {};
    //Getting URL parameters that will be given with prop.
    if(props.url){
        const urlParams = new URLSearchParams(props.url.split('?')[1]);
        urlParams.forEach((value, key) => {
            urlParamsObj[key] = value;
        });
    }
    //Looping SQL parameters from app config
    if(appConfig.queryParameters){
        for ( let [key, value] of Object.entries(appConfig.queryParameters)) {
            if (value.type === 'number') {
                sqlStatementParameters[key] = urlParamsObj[key] ? parseInt(urlParamsObj[key]) : parseInt(value.default, 10) ?? null;
            } else {
                sqlStatementParameters[key] = urlParamsObj[key] ? stringToBoolean(urlParamsObj[key]) : stringToBoolean(value.default) ?? null;
            }
        }
    }
    chartDataObject.value.recordSource.sqlStatementParameters = sqlStatementParameters;

    useDataObjectEventListener(chartDataObject.value, 'DataLoaded', ()=>{
        emits('loaded');
    })

    return chartDataObject.value
}

//Needed to handle string true/false conversion for required type
function stringToBoolean(str) {
    if (str && str.toLowerCase() === "true") return true;
    if (str && str.toLowerCase() === "false") return false;
    return str; // or throw an error
}

defineExpose({chartDataObject})
</script>